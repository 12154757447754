/* @flow */

import React from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { withBreakpoints } from 'react-breakpoints'
import { FaHome, FaSearch } from 'react-icons/fa'
import { FiX, FiChevronDown, FiChevronUp } from 'react-icons/fi'

import {
  PermissionContext,
  UserHasPermissions,
} from '../../../../infrastructure/components/Authorization'
import MobileNavigation from '../../../../infrastructure/components/Mobile/MobileNavigation'
import { changeWebshopCurrency } from '../../../shop/actions/index'
import {
  changeBrandContext,
  getAvailableBrands,
} from '../../../brands/actions/index'
import { refreshSession } from '../../../../infrastructure/actions/session'
import { regainAdmin } from '../../../admin/actions'
import AppContext from '../../../app-store/components/AppContext'

import { FakeLinkStyle, SplitComponent } from '../../../shared'
import SearchBar from '../../components/SearchBar'
import { openNavigationSearch } from '../../actions/search'
import NavigationRightSide from './NavigationRightSide'
import Notifications from './Notifications'
import { Submenu, SubmenuNavigationLink } from './shared'

const BrandNavigationBar = props => {
  const {
    adminEmail,
    currentBreakpoint,
    customer,
    entity,
    settings,
    toggleSpotlight,
    user,
  } = props

  const useNewColors = user.feature_flags.new_colors

  const [showMobileNavBar, setShowMobileNavBar] = React.useState(false)
  const [expandedMobileHeader, setExpandedMobileHeader] = React.useState(false)

  const toggleMobileNavigationSubItems = React.useCallback(
    key => {
      setExpandedMobileHeader(s => (s === key ? false : key))
    },
    [setExpandedMobileHeader]
  )

  const toggleMobileNavBar = React.useCallback(() => {
    setShowMobileNavBar(s => !s)
  }, [setShowMobileNavBar])

  const toggleSearch = React.useCallback(() => {
    if (toggleSpotlight) {
      toggleSpotlight()
    }
  }, [toggleSpotlight])

  const onHideMobileNavigation = React.useCallback(() => {
    setShowMobileNavBar(false)
  }, [setShowMobileNavBar])

  const navigationItems = React.useMemo(() => {
    return createNavigationLinks({
      customer,
      entity,
      onHideMobileNavigation,
      toggleSearch,
      user,
      useNewColors,
    })
  }, [
    customer,
    entity,
    onHideMobileNavigation,
    toggleSearch,
    user,
    useNewColors,
  ])

  const entityType = entity.entity_type

  const apps = entity.apps.map(a => a.app)

  const isMobile = currentBreakpoint === 'mobile'
  const isHacked = false //adminEmail === 'mk@traede.com'

  return (
    <NavigationContainer>
      <MainNavigationContainer useNewColors={useNewColors}>
        {isMobile && (
          <>
            <MobileNavigationHeader>
              <MobileNavigationToggle onClick={toggleMobileNavBar}>
                <span className="glyphicon glyphicon-menu-hamburger" />
              </MobileNavigationToggle>
            </MobileNavigationHeader>

            {/* currently we do not render mobile nav bar at all if it does not show,
            since the fixed overlay breaks links in order lists etc */}
            {showMobileNavBar && (
              <MobileNavigation
                header={
                  <>
                    <div>NAVIGATION</div>

                    <div
                      className="close"
                      onClick={() => setShowMobileNavBar(false)}
                    >
                      <FiX size={16} style={{ verticalAlign: 'middle' }} />
                    </div>
                  </>
                }
                headerStyle={{ height: 50 }}
                onHide={() => setShowMobileNavBar(false)}
                show={showMobileNavBar}
                wrapperClass="normal-scroll"
              >
                <MobileNavigation.ItemList>
                  {navigationItems.map(item => {
                    const subItems = item.sub_items || []

                    const content = (
                      <li key={item.key}>
                        {item.link && subItems.length == 0 && (
                          <Link to={item.link} onClick={onHideMobileNavigation}>
                            {item.mobile_label || item.label}
                          </Link>
                        )}
                        {item.link && subItems.length > 0 && (
                          <MobileNavigation.ItemListHeader
                            onClick={() =>
                              toggleMobileNavigationSubItems(item.key)
                            }
                            style={{ alignItems: 'center', display: 'flex' }}
                          >
                            <div style={{ flex: 1 }}>
                              {item.mobile_label || item.label}
                            </div>

                            {expandedMobileHeader == item.key && (
                              <FiChevronDown size={14} />
                            )}
                            {expandedMobileHeader != item.key && (
                              <FiChevronUp size={14} />
                            )}
                          </MobileNavigation.ItemListHeader>
                        )}
                        {!item.link &&
                          item.render_mobile &&
                          item.render_mobile({
                            toggleSearch,
                          })}
                      </li>
                    )

                    const wrapped = wrapInFeatureFlag(
                      wrapInPermission(wrapInAppContext(content, item), item),
                      item
                    )

                    return (
                      <>
                        {wrapped}

                        {expandedMobileHeader == item.key && (
                          <MobileNavigation.SubItemList>
                            {subItems.map(subItem => {
                              const innerContent = (
                                <li key={subItem.key}>
                                  <Link
                                    to={subItem.link}
                                    onClick={onHideMobileNavigation}
                                  >
                                    {subItem.mobile_label || subItem.label}
                                  </Link>
                                </li>
                              )

                              return wrapInFeatureFlag(
                                wrapInPermission(
                                  wrapInAppContext(innerContent, subItem),
                                  subItem
                                ),
                                subItem
                              )
                            })}
                          </MobileNavigation.SubItemList>
                        )}
                      </>
                    )
                  })}
                </MobileNavigation.ItemList>
              </MobileNavigation>
            )}
          </>
        )}

        {!isMobile && (
          <DesktopNavigationLeftSide>
            {navigationItems.map(item => {
              let content

              // icon only
              if (!item.label) {
                content = (
                  <MainNavigationIconOnlyContainer
                    useNewColors={useNewColors}
                    key={item.key}
                  >
                    {item.link && (
                      <NavigationItemLink
                        to={item.link}
                        useNewColors={useNewColors}
                      >
                        <span
                          className={item.icon}
                          style={{ paddingRight: 0 }}
                          tabIndex="-1"
                        />
                      </NavigationItemLink>
                    )}
                    {!item.link &&
                      item.render &&
                      item.render({
                        toggleSearch,
                      })}
                  </MainNavigationIconOnlyContainer>
                )

                // icon + label
              } else {
                content = (
                  <NavigationItem
                    key={item.key}
                    link={item.link}
                    icon={item.icon}
                    label={item.label}
                    permission={item.permission}
                    useNewColors={useNewColors}
                  >
                    {(item.sub_items || []).map(subItem => {
                      const subItemRender = (
                        <SubmenuNavigationItem
                          link={subItem.link}
                          label={subItem.label}
                          permission={subItem.permission}
                          newLabel={subItem.new_label}
                        />
                      )

                      return wrapInFeatureFlag(
                        wrapInPermission(
                          wrapInAppContext(subItemRender, subItem),
                          subItem
                        ),
                        subItem
                      )
                    })}
                  </NavigationItem>
                )
              }

              return wrapInFeatureFlag(
                wrapInPermission(wrapInAppContext(content, item), item),
                item
              )
            })}
          </DesktopNavigationLeftSide>
        )}
        <NavigationRightSide
          useNewColors={useNewColors}
          type="main"
          {...props}
        />
      </MainNavigationContainer>
    </NavigationContainer>
  )
}

const BrandNavigationBarWithBreakpoints = withBreakpoints(BrandNavigationBar)

export default BrandNavigationBarWithBreakpoints

const NavigationContainer = styled.div`
  position: fixed;
  z-index: 1;
  width: 100%;

  @media print {
    display: none;
  }
`

const MainNavigationContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${({ useNewColors }) => {
    return useNewColors
      ? css`
          background: #262626;
          height: 50px;
          font-size: 12px;
        `
      : css`
          background: #385567;
          height: 62px;
        `
  }}
`

const DesktopNavigationLeftSide = styled.div`
  display: flex;
  flex: 1;
`

const MobileNavigationHeader = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  padding: 0 15px;
`

const MobileNavigationToggle = styled.button.attrs({
  type: 'button',
})`
  background: transparent;
  border: 1px solid white;
  border-radius: 5px;
  color: #ccc;
  font-size: 20px;
  padding: 5px 15px;
  outline: 0;
`

type NavigationItemProps = {
  children?: React.Node,
  label: string,
  link: string,
  icon?: string,
  isSubmenu?: boolean,
  permission?: string | Array<string>,
  subtitle?: string,
}

const NavigationItem = (props: NavigationItemProps) => {
  const {
    children,
    customIcon,
    permission,
    link,
    icon,
    label,
    subtitle,
    useNewColors,
  } = props

  return (
    <PermissionContext permission={permission}>
      <MainNavigationItemContainer
        className={children && children.length ? 'dropdown' : ''}
        useNewColors={useNewColors}
      >
        <NavigationItemLink
          to={link}
          activeClassName="active"
          tabIndex="-1"
          useNewColors={useNewColors}
        >
          {icon && <span className={'lnr lnr-' + icon} />}
          {customIcon && customIcon}
          <NavigationItemTitle useNewColors={useNewColors}>
            <div>{label}</div>
            <NavigationItemSubtitle useNewColors={useNewColors}>
              {subtitle}
            </NavigationItemSubtitle>
          </NavigationItemTitle>
        </NavigationItemLink>
        {children && children.length ? <Submenu>{children}</Submenu> : null}
      </MainNavigationItemContainer>
    </PermissionContext>
  )
}

const SubmenuNavigationItem = ({
  icon,
  label,
  link,
  permission,
  newLabel,
}: NavigationItemProps) => {
  return (
    <SubmenuNavigationItemContainer>
      <PermissionContext permission={permission}>
        <SubmenuNavigationLink
          className="clearfix"
          to={link}
          activeClassName="active"
          tabIndex="-1"
        >
          {label}

          {newLabel && <NewLabel>NEW</NewLabel>}
        </SubmenuNavigationLink>
      </PermissionContext>
    </SubmenuNavigationItemContainer>
  )
}

const NavigationItemLink = styled(Link)``

const NavigationLinkCss = css`
  align-items: center;
  color: white;
  display: flex;
  padding: 0 14px;
  height: ${({ useNewColors }) => (useNewColors ? '50px' : '62px')};
  white-space: nowrap;
  overflow-wrap: break-word;

  &:hover {
    color: white;
    background: ${({ useNewColors }) => (useNewColors ? '#000' : '#344854')};
  }
`

const NewLabel = styled.span`
  background-color: #e1e7ea;
  border-radius: 2px;
  color: #8fa0ad;
  margin-left: 5px;
  font-size: 10px;
  padding: 2px 4px;
  text-transform: uppercase;
`

const MainNavigationBaseItemContainer = styled.div`
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;

  &.dropdown:hover > ${Submenu} {
    display: block;
  }

  > ${NavigationItemLink} {
    ${NavigationLinkCss};
  }
`

const MainNavigationIconOnlyContainer = styled(MainNavigationBaseItemContainer)`
  > ${NavigationItemLink} {
    font-size: ${({ useNewColors }) => (useNewColors ? '14px' : '18px')};
  }
`

const MainNavigationSearchButton = styled.button.attrs({
  type: 'button',
})`
  ${NavigationLinkCss};
  background: transparent;
  border: 0;
  font-size: ${({ useNewColors }) => (useNewColors ? '14px' : '18px')};
  outline: 0;
`

const MainNavigationItemContainer = styled(MainNavigationBaseItemContainer)`
  .lnr {
    ${({ useNewColors }) => {
      return useNewColors
        ? css`
            font-size: 14px;
            margin-right: 5px;
          `
        : css`
            font-size: 16px;
            margin-right: 7px;
          `
    }};
  }

  @media (max-width: 1500px) {
    .lnr {
      display: none;
    }
  }
`

const SubmenuNavigationItemContainer = styled.div`
  > ${NavigationItemLink} {
  }
`

const NavigationItemSubtitle = styled.div``

const NavigationItemTitle = styled.div`
  position: relative;

  > ${NavigationItemSubtitle} {
    color: #e2e2e2;
    font-size: 10px;
    position: absolute;
    top: 85%;
  }
`

const wrapInFeatureFlag = (innerRender, item) => {
  if (!item.feature_flag) {
    return innerRender
  }

  return (
    <SplitComponent
      entityOrUser
      splitKey={item.feature_flag}
      not={item.feature_flag_not}
      true={innerRender}
    />
  )
}

const wrapInPermission = (innerRender, item) => {
  if (!item.permission) {
    return innerRender
  }

  return (
    <PermissionContext permission={item.permission}>
      {innerRender}
    </PermissionContext>
  )
}

const wrapInAppContext = (innerRender, item) => {
  if (!item.app) {
    return innerRender
  }

  return (
    <AppContext app={item.app} not={item.app_not}>
      {innerRender}
    </AppContext>
  )
}

const createNavigationLinks = ({
  customer,
  entity,
  onHideMobileNavigation,
  toggleSearch,
  user,
  useNewColors,
}) => {
  const navigationItems = [
    {
      permission: 'dashboard',
      key: 'home',
      link: '/',
      icon: 'lnr lnr-home',
      mobile_label: 'Dashboard',
    },
    {
      key: 'search',
      render: () => {
        return (
          <MainNavigationSearchButton
            className="active"
            onClick={toggleSearch}
            tabIndex="-1"
            useNewColors={useNewColors}
          >
            <span
              className="lnr lnr-magnifier"
              style={{ paddingRight: 0 }}
            ></span>
          </MainNavigationSearchButton>
        )
      },
      render_mobile: () => {
        return (
          <MobileNavigation.FakeLink
            onClick={() => {
              toggleSearch()
              onHideMobileNavigation()
            }}
          >
            Search
          </MobileNavigation.FakeLink>
        )
      },
      icon: 'lnr lnr-home',
    },
    {
      key: 'orders',
      link: '/orders',
      icon: 'file-empty',
      label: 'Sales',
      permission: 'orders:read',

      sub_items: [
        {
          app: ['Shopify', 'Woocommerce'],
          app_not: true,
          link: '/orders',
          label: 'Order List',
          permission: 'orders:read',
        },
        {
          app: ['Shopify', 'Woocommerce'],
          link: '/orders/b2b',
          label: 'B2B Orders',
          permission: 'orders:read',
        },
        {
          app: ['Shopify', 'Woocommerce'],
          link: '/orders/b2c',
          label: 'B2C Orders',
          permission: 'orders:read',
        },
        {
          link: '/invoices',
          label: 'Invoices',
          permission: 'orders:invoices:read',
        },
        {
          link: '/prepayments',
          label: 'Prepayments',
          permission: 'orders:prepayments:read',
        },
        {
          link: '/orders/drops',
          label: 'Drops',
          permission: 'orders:drops:read',
        },
        {
          link: '/returns',
          label: 'Returns',
          permission: 'orders:returns:read',
        },
        {
          link: '/showrooms',
          label: 'Showrooms',
          permission: 'showrooms:read',
          freemium: false,
        },
        {
          link: '/campaigns',
          label: 'Linesheets',
          permission: 'linesheets:read',
          new_label: true,
        },
      ],
    },
    {
      key: 'shipments',
      link: '/fulfilment',
      icon: 'location',
      label: 'Fulfilment',
      permission: 'orders:shipments:read',
      app: 'Shipments',

      sub_items: [
        {
          link: '/fulfilment',
          label: 'Dashboard',
          permission: 'orders:shipments:read',
        },
        {
          link: '/shipments/planner',
          label: 'Shipping planner',
          permission: 'orders:shipments:read',
          new_label: true,
          feature_flag: 'shipping_planner',
        },
        {
          link: '/shipments/list',
          label: 'Shipments',
          permission: 'orders:shipments:read',
        },
      ],
    },
    {
      key: 'webshop',
      link: `/shop/${entity.id}`,
      icon: 'cart',
      label: 'Webshop',
      permission: 'webshop:browse',
      freemium: false,
    },
    {
      key: 'customers',
      link: '/customers',
      icon: 'users',
      label: 'Customers',
      permission: 'customers:basic:read',

      sub_items: [
        {
          link: '/customers',
          label: 'Customer List',
          permission: 'customers:basic:read',
        },
        {
          link: '/customer-groups',
          label: 'Customer Groups',
          permission: 'customers:groups:read',
          freemium: false,
        },
        {
          link: '/price-lists',
          label: 'Price Lists',
          permission: 'customers:price-lists:read',
        },
        {
          link: '/customers/import',
          label: 'Import customers',
          permission: 'customers:importer',
          custom_check: ({ entity, customer }) => {
            return (
              entity.freemium ||
              (customer ? customer.customers_importer : false)
            )
          },
        },
      ],
    },
    {
      key: 'inventory',
      link: '/inventory',
      icon: 'layers',
      label: 'Inventory',
      permission: 'inventory:read',

      sub_items: [
        {
          link: '/inventory',
          label: 'Inventory list',
          permission: 'inventory:read',
        },
        {
          link: '/consignment',
          label: 'Consignment',
          permission: 'consignment:read',
        },
        {
          link: '/inventory/reservations',
          label: 'Reservations',
          permission: 'inventory:write',
          agent: false,
        },
        {
          link: '/inventory/report',
          label: 'Inventory report',
          permission: 'inventory:write',
          agent: false,
          feature_flag: 'inventory_report',
        },
        {
          link: '/inventory/stock-counts',
          label: 'Stock count',
          permission: 'stock_count:read',
        },
        {
          link: '/inventory/import',
          label: 'Import inventory',
          permission: 'inventory:importer',
          custom_check: ({ entity, customer }) => {
            return (
              entity.freemium ||
              (customer ? customer.inventory_importer : false)
            )
          },
        },
      ],
    },
    {
      key: 'products',
      link: '/products',
      icon: 'shirt',
      label: 'Products',
      permission: 'products:read',

      sub_items: [
        {
          link: '/products',
          label: 'Product List',
          permission: 'products:read',
        },
        {
          link: '/product-groups',
          label: 'Product Groups',
          permission: 'categories:read',
        },
        {
          link: '/subbrands',
          label: 'Brands',
          permission: 'subbrands:read',
        },
        {
          link: '/categories',
          label: 'Categories',
          permission: 'categories:read',
        },
        {
          link: '/collections',
          label: 'Collections',
          permission: 'collections:read',
        },
        {
          link: '/segmentation',
          label: 'Segmentation',
          permission: 'segmentation:read',
        },
        {
          link: '/products/import',
          label: 'Import products',
          permission: 'products:importer',
          custom_check: ({ entity, customer }) => {
            return (
              entity.freemium || (customer ? customer.products_importer : false)
            )
          },
        },
        {
          link: '/launch-events',
          label: 'Launch events',
          feature_flag: 'launch_events',
        },
        {
          link: '/products/images-import',
          label: 'Image Import',
        },
      ],
    },
    {
      key: 'production',
      link: '/production/orders',
      icon: 'pencil',
      label: 'Production',
      permission: 'production:orders:read',
      app: 'Production',

      sub_items: [
        {
          link: '/production',
          label: 'Dashboard',
          permission: 'production:orders:read',
        },
        {
          link: '/production/orders',
          label: 'Production orders',
          permission: 'production:orders:read',
        },
        {
          link: '/production/orders/delivery-notes',
          label: 'Deliveries',
          permission: 'production:delivery_notes:read',
        },
        {
          link: '/production/production-prepayments',
          label: 'Prepayments',
          permission: 'production:prepayments:read',
          app: 'Fortnox',
        },
        {
          link: '/production/overhead-cost-invoices',
          label: 'Overhead cost invoices',
          permission: 'production:overhead_cost_invoices:read',
          app: 'Fortnox',
        },
        {
          link: '/production/delivery-chart',
          label: 'Delivery chart',
          permission: 'production:delivery_notes:read',
        },
        {
          link: '/production/suppliers',
          label: 'Suppliers',
          permission: 'production:suppliers:read',
        },
      ],
    },
    {
      key: 'reports',
      link: '/reports/revenue',
      icon: 'chart-bars',
      label: 'Reports',
      permission: 'reports:browse',

      sub_items: [
        {
          link: '/reports',
          label: 'Report Generator (BETA)',
          permission: 'reports:browse',
          feature_flag: 'remove_legacy_reports',
          feature_flag_not: true,
        },
        {
          link: '/reports/order_count',
          label: 'Order Count',
          feature_flag: 'remove_legacy_reports',
          feature_flag_not: true,
        },
        {
          link: '/reports/order_totals',
          label: 'Order Totals',
          feature_flag: 'remove_legacy_reports',
          feature_flag_not: true,
        },
        {
          link: '/reports/revenue',
          label: 'Revenue',
        },
        {
          link: '/reports/rankings',
          label: 'Top Rankings',
        },
        {
          link: '/reports/claims',
          label: 'Claims',
        },
        {
          link: '/reports/sales-goals',
          label: 'Sales Goals',
          permission: 'reports:sales-goals:read',
        },
        {
          link: '/reports/warehousing',
          label: 'Warehousing',
          agent: false,
        },
        {
          link: '/reports/cancellations',
          label: 'Cancellations',
          agent: false,
        },
        {
          link: '/reports/unpaid-invoices',
          label: 'Unpaid invoices',
          new_label: true,
          permission: 'unpaid_invoices:read',
        },
        {
          link: '/reports/intrastat',
          label: 'Intrastat',
          agent: false,
        },
        {
          link: '/reports/one-stop-shop',
          label: 'One stop shop',
          new_label: true,
          agent: false,
        },
        {
          link: '/reports/commission',
          label: 'Commission',
          permission: 'customers:commission-reports:read',
        },
      ],
    },
    {
      key: 'app_store',
      link: '/apps',
      icon: 'rocket',
      label: 'App Store',
      permission: 'apps:read',

      sub_items: [
        {
          link: '/apps/riverty',
          label: 'Riverty',
          app: 'Riverty',
        },
        {
          link: '/apps/economic',
          label: 'e-conomic',
          app: 'Economic',
        },
        {
          link: '/apps/exact',
          label: 'Exact',
          app: 'Exact',
        },
        {
          link: '/apps/fortnox',
          label: 'Fortnox',
          app: 'Fortnox',
        },
        {
          link: '/campaigns',
          label: 'Linesheets (Campaigns)',
          permission: 'linesheets:read',
        },
        {
          link: `/file-bank/${entity.id}`,
          label: 'File Bank preview',
          app: 'File Bank',
          permission: 'filebank:read',
        },
        {
          link: `/apps/file-bank`,
          label: 'File Bank settings',
          app: 'File Bank',
          permission: 'filebank:read',
        },
        {
          link: '/apps/create2stay',
          label: 'create2STAY',
          app: 'Create2Stay',
        },
        {
          link: '/apps/shipments',
          label: 'Shipments',
          app: 'Shipments',
        },
        {
          link: '/apps/woocommerce',
          label: 'Woocommerce',
          app: 'Woocommerce',
        },
        {
          link: '/apps/shopify',
          label: 'Shopify',
          app: 'Shopify',
        },
        {
          link: '/apps/data-export',
          label: 'Data export',
          app: 'DataExport',
        },
        {
          link: '/apps/mailchimp',
          label: 'Mailchimp',
          app: 'Mailchimp',
        },
        {
          link: '/apps/production',
          label: 'Production',
          app: 'Production',
        },
        {
          link: '/apps/eaccounting',
          label: 'Eaccounting',
          app: 'eAccounting',
        },
        {
          link: '/apps/thetis-pack',
          label: 'Thetis Pack',
          app: 'ThetisPack',
        },
        {
          link: '/apps/dsv',
          label: 'DSV',
          app: 'Dsv',
        },
        {
          link: '/apps/rhenus',
          label: 'RHENUS',
          app: 'Rhenus',
        },
        {
          link: '/apps/tmc',
          label: 'TMC',
          app: 'Tmc',
        },
        {
          link: '/apps/dnb',
          label: 'DNB',
          app: 'Dnb',
        },
        {
          link: '/apps/edi',
          label: 'EDI',
          app: 'Edi',
        },
        {
          link: '/apps/hlt',
          label: 'HLT',
          app: 'Hlt',
        },
        {
          link: '/apps/lector',
          label: 'Lector',
          app: 'Lector',
        },
        {
          link: '/apps/pos',
          label: 'POS',
          app: 'GenericPos',
        },
        {
          link: '/apps/business-central',
          label: 'Business Central',
          app: 'BusinessCentral',
        },
        {
          link: '/apps/tripletex',
          label: 'Tripletex',
          app: 'Tripletex',
        },
        {
          link: '/apps/xero',
          label: 'Xero',
          app: 'Xero',
        },
        {
          link: '/apps/quickbooks',
          label: 'Quickbooks',
          app: 'Quickbooks',
        },
        {
          link: '/apps/webshipper',
          label: 'Webshipper',
          app: 'Webshipper',
        },
        {
          link: '/apps/zalando',
          label: 'Zalando',
          app: 'Zalando',
        },
        {
          link: '/apps/faire',
          label: 'Faire',
          app: 'Faire',
        },
        {
          link: '/apps/sendcloud',
          label: 'Sendcloud',
          app: 'Sendcloud',
        },
        {
          link: '/apps/shipmondo',
          label: 'Shipmondo',
          app: 'Shipmondo',
        },
        {
          link: '/apps/ongoing',
          label: 'Ongoing',
          app: 'Ongoing',
        },
        {
          link: '/apps/pakkecenternord',
          label: 'PakkecenterNord',
          app: 'PakkecenterNord',
        },
        {
          link: '/apps/picqer',
          label: 'Picqer',
          app: 'Picqer',
        },
        {
          link: '/apps/klaviyo',
          label: 'Klaviyo',
          app: 'Klaviyo',
        },
        {
          link: '/apps/delogue',
          label: 'Delogue',
          app: 'Delogue',
        },
        {
          link: '/apps/systemtransport',
          label: 'Systemtransport',
          app: 'Systemtransport',
        },
        {
          link: '/apps/midtfactoring',
          label: 'Midt Factoring',
          app: 'MidtFactoring',
        },
        {
          link: '/apps/svea',
          label: 'Svea',
          app: 'Svea',
        },
        {
          link: '/apps/epay',
          label: 'ePay',
          app: 'Epay',
        },
        {
          link: '/apps/quickpay',
          label: 'Quickpay',
          app: 'Quickpay',
        },
        {
          link: '/document-exchange',
          label: 'Document exchange',
          permission: 'document-exchange:read',
        },
      ],
    },
    {
      key: 'admin',
      link: '/admin',
      icon: 'cog',
      label: 'Admin',
      admin: true,

      sub_items: [
        {
          link: '/admin/users',
          label: 'Users',
        },
        {
          link: '/admin/paying-customers',
          label: 'Paying Customers',
        },
        {
          link: '/admin/feature-flags',
          label: 'Feature flags',
        },
        {
          link: '/admin/agents',
          label: 'Agents',
        },
        {
          link: '/admin/queue',
          label: 'Queue',
        },
        {
          link: '/admin/imports',
          label: 'Imports',
        },
        {
          link: '/admin/setting-changes',
          label: 'Setting changes',
        },
        {
          link: '/admin/news',
          label: 'News',
        },
        {
          link: '/admin/reports',
          label: 'Reports',
        },
        {
          link: '/admin/miscellaneous-tools',
          label: 'Miscellaneous Tools',
        },
      ],
    },

    // FREMIUM NAVIGATION

    {
      key: 'showrooms',
      link: '/showrooms',
      icon: 'license',
      label: 'Showrooms',
      permission: 'showrooms:browse',
      freemium: true,
    },
    {
      key: 'file-bank',
      link: '/apps/file-bank',
      icon: 'picture',
      label: 'File Bank',
      permission: 'filebank:read',
      freemium: true,

      sub_items: [
        {
          link: `/file-bank/${entity.id}`,
          label: 'Visit',
        },
        {
          link: '/apps/file-bank',
          label: 'Edit',
          permission: 'filebank:write',
        },
      ],
    },

    /*
      When user does not have access to App Store, we might want to at
      least show file bank, linesheets or document exchange separately.
    */
    {
      key: 'file-bank',
      link: `/file-bank/${entity.id}`,
      label: 'File Bank',
      permission: 'filebank:read',
      permission_not: 'apps:read',
      freemium: false,
    },
    {
      key: 'campaigns',
      link: '/campaigns',
      label: 'Campaigns',
      permission: 'linesheets:read',
      permission_not: 'apps:read',
      freemium: false,
    },
  ]

  const isAdmin = user.user_type === 'admin'
  const isAgent = user.user_type === 'agent'

  const mappedNavigationItems = navigationItems
    .map(item => {
      if (item.admin === true && !isAdmin) {
        return null
      }

      if (item.custom_check) {
        if (!item.custom_check({ entity, customer })) {
          return null
        }
      }

      if (item.freemium !== undefined) {
        if (item.freemium !== entity.freemium) {
          return null
        }
      }

      if (item.agent !== undefined) {
        if (item.agent !== isAgent) {
          return null
        }
      }

      if (
        item.permission_not &&
        !!UserHasPermissions(user, item.permission_not)
      ) {
        return null
      }

      const copy = { ...item }

      if (copy.sub_items) {
        copy.sub_items = copy.sub_items
          .map(subItem => {
            if (subItem.admin === true && !isAdmin) {
              return null
            }

            if (subItem.custom_check) {
              if (!subItem.custom_check({ entity, customer })) {
                return null
              }
            }

            if (subItem.freemium !== undefined) {
              if (subItem.freemium !== entity.freemium) {
                return null
              }
            }

            if (subItem.agent !== undefined) {
              if (subItem.agent !== isAgent) {
                return null
              }
            }

            if (
              subItem.permission_not &&
              !!UserHasPermissions(user, subItem.permission_not)
            ) {
              return null
            }

            return subItem
          })
          .filter(subItem => subItem)
      }

      return copy
    })
    .filter(item => item)

  return mappedNavigationItems
}
