/* @flow */

import * as React from 'react'
import { PureComponent } from 'react'
import styled from 'styled-components'
import { Portal } from 'react-portal'
import sortBy from 'lodash/sortBy'
import memoize from 'memoize-one'
import { Modal } from 'react-bootstrap'
import { Formik, FieldArray } from 'formik'

import { useDataCacheValues } from '../hooks'
import { createEmptyFilter } from './shared'
import FilterForm from './FilterForm'
import FiltersMenu from './FiltersMenu'
import { createDateFilterLabel } from '../../infrastructure/components/FilterForm/Filters/DateFilter'

import {
  CheckboxInput,
  ControlLabel,
  FormGroup,
  ListFormInput,
  SaveButton,
} from '../../infrastructure/components/Formik'

import type { DataTableAttribute, Filter } from '../types'
import { renderDate } from '../shared'

type Props = {
  attributes: Array<DataTableAttribute>,
  activeFilter: ?Filter,
  children: React.Node,
  filters: Array<Filter>,
  onChange: (
    filters: Array<Filter>,
    attribute: DataTableAttribute,
    action: 'add' | 'edit' | 'remove',
    changedFilter: Filter
  ) => void,
  onClickFilter: (filter: Filter) => void,
  onHideFilter: () => void,
  pinnedAttributes: Array<string>,
}

type State = {
  leftPosition: number,
  topPosition: number,
}

const FiltersList = ({
  activeFilter,
  attributes,
  className,
  children,
  dataCache,
  dataCacheActions,
  filters,
  onChange,
  onClickFilter,
  onHideAddAdvancedFilter,
  onHideFilter,
  readOnly = false,
  pinnedAttributes = [],
  preFiltersRender,
  showAddAdvancedFilter,
  ...rest
}: Props) => {
  const { leftPosition, topPosition } = React.useState({
    leftPosition: 0,
    topPosition: 0,
  })

  const combinedFilters = combineFiltersAndPinnedAttributes(
    filters,
    pinnedAttributes,
    attributes
  )

  const sortedAttributes = React.useMemo(() => {
    return sortBy(attributes, 'label')
  }, [attributes])

  const onFilterChange = React.useCallback(
    (values: $Shape<Filter>, filter: Filter, attribute: DataTableAttribute) => {
      const index = filters.findIndex(f => f === filter)

      const filtersCopy = [...filters]
      const newFilter = { ...filtersCopy[index], ...values }

      if (index !== -1) {
        filtersCopy[index] = newFilter

        if (newFilter.type !== 'advanced') {
          if (
            (Array.isArray(newFilter.value) && newFilter.value.length === 0) ||
            (!Array.isArray(newFilter.value) &&
              (newFilter.value === '' || newFilter.value === undefined))
          ) {
            if (newFilter.operator !== 'no_value') {
              filtersCopy[index].error = 'is missing value'
            }
          } else {
            delete filtersCopy[index].error
          }
        }

        onChange(filtersCopy, attribute, 'edit', newFilter)
      } else {
        filtersCopy.push(newFilter)

        onChange(filtersCopy, attribute, 'add', newFilter)
      }

      if (onHideAddAdvancedFilter) {
        onHideAddAdvancedFilter()
      }
    },
    [filters, onChange, onHideAddAdvancedFilter]
  )

  const onFilterClick = React.useCallback(
    (filter: Filter) => {
      onClickFilter(filter)
    },
    [onClickFilter]
  )

  const removeFilter = React.useCallback(
    (filter: Filter, attribute: DataTableAttribute) => {
      onChange(
        filters.filter(f => f !== filter),
        attribute,
        'remove',
        filter
      )
    },
    [filters, onChange]
  )

  return (
    /* className used by styled-components */
    <Container className={className}>
      {preFiltersRender}
      {combinedFilters.map(filter => {
        const attribute = attributes.filter(
          attribute => attribute.property === filter.key
        )[0]
        const errorneous = Boolean(filter.error)

        // sometimes we actually do want to have filters without an attribute
        // e.g. in Customers SalesTab we filter by customer_id which is not
        // an attribute returned by the attributes API
        if (filter.type !== 'advanced' && !attribute) {
          return null
        }

        return (
          <FilterButtonContainer key={JSON.stringify(filter)}>
            <ButtonShape errorneous={errorneous}>
              <FilterButton
                errorneous={errorneous}
                onClick={() => onFilterClick(filter)}
                disabled={readOnly}
              >
                {filter.type !== 'advanced' && attribute && (
                  <>
                    <AttributeLabel>{attribute.label}</AttributeLabel>
                    {filter.error && <span> {filter.error}</span>}
                    {!filter.error && (
                      <span>
                        {' '}
                        <FilterLabel
                          filter={filter}
                          attribute={attribute}
                          dataCache={dataCache}
                          dataCacheActions={dataCacheActions}
                        />
                      </span>
                    )}
                  </>
                )}
                {filter.type === 'advanced' && (
                  <>
                    <AttributeLabel>Advanced</AttributeLabel>
                    {filter.error && <span> {filter.error}</span>}
                    {!filter.error && (
                      <span>
                        {' '}
                        {advancedFilterToLabel(
                          filter,
                          attributes,
                          dataCache,
                          dataCacheActions
                        )}
                      </span>
                    )}
                  </>
                )}
              </FilterButton>
              {!readOnly && (
                <RemoveButton
                  onClick={e => {
                    e.preventDefault()
                    removeFilter(filter, attribute)
                  }}
                >
                  <span className="glyphicon glyphicon-remove" />
                </RemoveButton>
              )}
            </ButtonShape>

            {activeFilter === filter && filter.type !== 'advanced' && (
              <FilterForm
                attribute={attribute}
                dataCache={dataCache}
                dataCacheActions={dataCacheActions}
                filter={activeFilter}
                onHide={() => onHideFilter()}
                onSubmit={onFilterChange}
              />
            )}
            {activeFilter === filter && filter.type == 'advanced' && (
              <AdvancedFilterFormModal
                activeAdvancedFilter={filter}
                attributes={sortedAttributes}
                onHide={() => onHideFilter()}
                onSave={values => onFilterChange(values, filter)}
                show
              />
            )}
          </FilterButtonContainer>
        )
      })}
      {showAddAdvancedFilter === true && (
        <AdvancedFilterFormModal
          attributes={sortedAttributes}
          onHide={onHideAddAdvancedFilter}
          onSave={onFilterChange}
          show={showAddAdvancedFilter === true}
        />
      )}
      {children}
    </Container>
  )
}

export default FiltersList

const combineFiltersAndPinnedAttributes = memoize(
  (filters, pinnedAttributesProperies, attributes) => {
    const pinnedAttributes = pinnedAttributesProperies
      .map(property => attributes.find(a => a.property === property))
      // might still be loading from server
      .filter(a => a)

    const currentFilteredProperties = filters.map(f => f.key)

    return sortFilters([
      ...filters,
      ...pinnedAttributes
        .filter(a => !currentFilteredProperties.includes(a.property))
        .map(attribute => createEmptyFilter(attribute)),
    ])
  }
)

const sortFilters = memoize(filters => sortBy(filters, 'label'))

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
`

const ButtonShape = styled.div`
  background: ${({ errorneous }: { errorneous: boolean }) =>
    errorneous ? 'rgba(254,217,219,.5)' : '#dedede'};
  border-radius: 5px;
  border: none;

  &:hover {
    background: ${({ errorneous }: { errorneous: boolean }) =>
      errorneous ? '#fed9db' : '#cee8ff'};
  }
`

const NoStyleButton = styled.button.attrs({ type: 'button' })`
  background: transparent;
  border: none;
  outline: none;
`

const FilterButton = styled(NoStyleButton)`
  font-size: 12px;

  &:hover {
    color: ${({ errorneous }: { errorneous: boolean }) =>
      errorneous ? '#fd3a57' : '#479ae6'};
  }
`

const RemoveButton = styled(NoStyleButton)`
  font-size: 10px;
  padding-left: 3px;
`

const FilterButtonContainer = styled.div`
  margin-left: 10px;
  position: relative;
`

const MenuOverflow = styled.div`
  flex: 1 1 auto;
  overflow-y: scroll;
`

const AttributeButton = styled.button`
  background: transparent;
  border: none;
  display: block;
  padding: 7px 6px;
  text-align: left;
  width: 100%;

  :hover {
    background-color: #318fb3;
    color: white;
  }
`

const AttributeLabel = styled.span`
  font-weight: bold;
`

const FilterLabel = ({
  filter,
  attribute,
  dataCache,
  dataCacheActions,
}: {
  filter: Filter,
  attribute: DataTableAttribute,
}) => {
  const [{ label: valueLabel, isFetching, isInitialized }, setValueLabel] =
    React.useState({
      label: null,
      isFetching: false,
      isInitialized: false,
    })

  React.useEffect(() => {
    if (attribute.data_cache_label) {
      attribute
        .data_cache_label({ dataCacheActions, filter })
        .then(newValue => {
          setValueLabel(s => ({
            label: filterValueToLabel(
              {
                ...filter,
                value: newValue,
              },
              attribute
            ),
            isFetching: false,
            isInitialized: true,
          }))
        })
    } else {
      setValueLabel(s => ({
        label: filterValueToLabel(filter, attribute),
        isFetching: false,
        isInitialized: true,
      }))
    }
  }, [attribute, filter, dataCache, dataCacheActions, setValueLabel])

  if (!isInitialized) {
    return null
  }

  const not = filter.not === true

  switch (filter.operator) {
    case 'in':
      return filter.value.length > 1
        ? `is${not ? ' NOT' : ''} one of ${valueLabel}`
        : `is${not ? ' NOT' : ''} ${valueLabel}`
    case 'eq':
      return `is${not ? ' NOT' : ''} ${valueLabel}`
    case 'ct':
      return `${not ? ' does NOT ' : ''}contains "${valueLabel}"`
    case 'sw':
      return `${not ? ' does NOT ' : ''}starts with "${valueLabel}"`
    case 'ew':
      return `${not ? ' does NOT ' : ''}ends with "${valueLabel}"`
    case 'lt':
      return `is${not ? ' NOT' : ''} lesser than ${valueLabel}`
    case 'gt':
      return `is${not ? ' NOT' : ''} greater than ${valueLabel}`
    case 'bt':
      return `is${not ? ' NOT' : ''} between ${valueLabel}`
    case 'date_relative_2':
      return valueLabel
    case 'date_past':
      return valueLabel
    case 'date_future':
      return valueLabel
    case 'no_value':
      return 'has no value'
    case 'has_value':
      return 'has value'
  }
}

const advancedFilterToLabel = (
  advancedFilter: Filter,
  attributes,
  dataCache,
  dataCacheActions
) => {
  const labels = []

  for (let filterGroup of advancedFilter.filter_groups) {
    const or = filterGroup.or || false

    const innerLabels = []
    for (let filter of filterGroup.filters) {
      const attribute = attributes.filter(
        attribute => attribute.property === filter.key
      )[0]

      if (attribute) {
        innerLabels.push(
          <span>
            {attribute.label}{' '}
            <FilterLabel
              attribute={attribute}
              filter={filter}
              dataCache={dataCache}
              dataCacheActions={dataCacheActions}
            />
          </span>
        )
      }
    }

    const filterGroupLabels = []
    for (let [i, innerLabel] of innerLabels.entries()) {
      let glue =
        i !== innerLabels.length - 1 ? <span>{or ? ' OR ' : ' AND '}</span> : ''

      filterGroupLabels.push(
        <span>
          {i == 0 && `(`}
          {innerLabel}
          {glue}
          {i == innerLabels.length - 1 && `)`}
        </span>
      )
    }

    labels.push(filterGroupLabels)
  }

  const mappedLabels = []
  for (let [i, labelsOfFilterGroup] of labels.entries()) {
    let glue = i !== labels.length - 1 ? <span>{' AND '}</span> : ''

    mappedLabels.push(
      <span>
        {labelsOfFilterGroup}
        {glue}
      </span>
    )
  }

  return mappedLabels
}

const filterValueToLabel = (
  filter: Filter,
  attribute: DataTableAttribute
): string => {
  if (filter.value === '' || filter.value === undefined) {
    return ''
  }

  switch (attribute.type) {
    case 'enum':
      const valueObject = attribute.values.filter(
        v => v.value === filter.value
      )[0]

      if (!valueObject) {
        return 'unknown value'
      }

      return valueObject.label
    case 'render':
    case 'array':
    case 'checkbox':
      if (!Array.isArray(filter.value)) {
        return filter.value
      }

      // $FlowIssue
      return filter.operator === 'bt'
        ? `${filter.value[0]} and ${filter.value[1]}`
        : replaceNullValue(filter.value.join(', '))
    case 'date':
      if (filter.operator === 'date_relative_2') {
        return createDateFilterLabel('date_relative_2', filter.value)
      } else if (filter.operator === 'date_past') {
        return createDateFilterLabel('date_past', filter.value)
      } else if (filter.operator === 'date_future') {
        return createDateFilterLabel('date_future', filter.value)
      } else if (filter.operator === 'bt') {
        return `${renderDate(filter.value[0])} - ${renderDate(filter.value[1])}`
      } else {
        if (Array.isArray(filter.value)) {
          return `${renderDate(filter.value[0])}`
        }
        return filter.value ? renderDate(filter.value) : 'no value'
      }
    default:
      // $FlowIssue
      return replaceNullValue(filter.value)
  }
}

const replaceNullValue = input => {
  if (!(typeof input === 'string')) {
    return input
  }

  return input.replace('__traede_null', 'no value')
}

const AdvancedFilterFormModal = ({
  activeAdvancedFilter,
  attributes,
  onHide,
  onSave: propsOnSave,
  show,
}: {
  onHide: Function,
  onSave: Function,
  show: boolean,
}) => {
  const initialValues = React.useMemo(() => {
    if (!activeAdvancedFilter) {
      return {
        filter_groups: [],
        type: 'advanced',
      }
    }

    return activeAdvancedFilter
  }, [activeAdvancedFilter])

  const onSave = React.useCallback(
    values => {
      for (let filterGroup of values.filter_groups) {
        delete filterGroup.show_filter_menu
      }

      propsOnSave(values)
    },
    [propsOnSave]
  )

  return (
    <Modal show={show} onHide={onHide} bsSize="large">
      <Formik
        initialValues={initialValues}
        onSubmit={onSave}
        render={({ handleSubmit, isSubmitting, setFieldValue, values }) => {
          const wrappedSubmit = e => {
            e.stopPropagation()
            handleSubmit(e)
          }

          return (
            <form onSubmit={wrappedSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Advanced filter</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ListFormInput
                  addNewLabel="Add new condition"
                  defaultValue={{
                    filters: [],
                    or: false,
                  }}
                  name="filter_groups"
                  values={values.filter_groups}
                  renderFields={(nameCreator, value) => {
                    const onChange = updatedFilters => {
                      setFieldValue(nameCreator('filters'), updatedFilters)
                    }

                    return (
                      <FilterGroupContainer>
                        <FiltersContainer>
                          <FormGroup>
                            <ControlLabel>Filters</ControlLabel>
                            <FiltersList
                              activeFilter={value.show_filter_menu}
                              attributes={attributes}
                              filters={value.filters}
                              onChange={onChange}
                              onClickFilter={filter =>
                                setFieldValue(
                                  nameCreator('show_filter_menu'),
                                  filter
                                )
                              }
                              onHideFilter={() =>
                                setFieldValue(
                                  nameCreator('show_filter_menu'),
                                  false
                                )
                              }
                            >
                              <FiltersMenu
                                attributes={attributes}
                                enableAdvancedFilter={false}
                                filters={value.filters}
                                onChange={onChange}
                                pinnable={false}
                              />
                            </FiltersList>
                          </FormGroup>
                        </FiltersContainer>
                        <FormGroup>
                          <CheckboxInput
                            id={nameCreator('or')}
                            label="Condition is OR-type instead of AND-type"
                            name={nameCreator('or')}
                          />
                        </FormGroup>
                      </FilterGroupContainer>
                    )
                  }}
                />
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-white"
                  onClick={onHide}
                >
                  Cancel
                </button>
                <SaveButton submitting={isSubmitting}>Save</SaveButton>
              </Modal.Footer>
            </form>
          )
        }}
      />
    </Modal>
  )
}

const FiltersContainer = styled.div`
  display: flex;
  margin-top: 20px;
`

const FilterGroupContainer = styled.div`
  border: 2px dashed #ccc;
  margin-bottom: 10px;
  padding: 10px;
`
