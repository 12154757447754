/* @flow */

import * as React from 'react'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'
import intersection from 'lodash/intersection'
import uniq from 'lodash/uniq'
import classnames from 'classnames'

import type { User } from '../../types'

type PermissionContextProps = {
  admin?: boolean,
  children?: React.Node,
  permission?: string | Array<string>,
  user: User,
}

const UnconnectedPermissionContext = ({
  admin,
  children,
  permission,
  not = false,
  user,
}: PermissionContextProps) => {
  let hasPermission = UserHasPermissions(user, permission, false, admin)
  if (not) {
    hasPermission = !hasPermission
  }

  return hasPermission ? children : null
}

export const PermissionContext = connect(state => ({
  user: state.session.user,
}))(UnconnectedPermissionContext)

type PermissionCssProps = {
  children?: React.Node,
  className?: string,
  falseClass: string,
  trueClass: string,
  permission: string | Array<string>,
  type?: string,
  user: User,
}

const UnconnectedPermissionCss = (props: PermissionCssProps) => {
  const {
    admin,
    children,
    className = '',
    falseClass,
    trueClass,
    permission,
    type = 'div',
    user,
    ...rest
  } = props

  const hasPermission = UserHasPermissions(user, permission, false, admin)

  let classes =
    classnames({
      [falseClass]: !hasPermission,
      [trueClass]: hasPermission,
    }) + ` ${className}`

  const elementProps = Object.assign({}, rest, { className: classes })

  return React.createElement(type, elementProps, children)
}

export const PermissionCss = connect(state => ({
  user: state.session.user,
}))(UnconnectedPermissionCss)

export const UserHasPermissions = (
  user: User,
  inputPermission: string | Array<string>,
  anyMode: boolean = false,
  admin?: boolean = false
): boolean => {
  // Temporary fix. For some reason when logging out these permission contexts are usually updated
  // first. However, as users are null at this point this code will throw an exception and the
  // log out is unsuccesful.
  if (!user.roles) {
    return false
  }

  // We just need to check if the user is an admin
  if (admin === true) {
    return user.roles.filter(r => r.role_type === 'admin').length > 0
  }

  let permission =
    typeof inputPermission === 'string' ? [inputPermission] : inputPermission

  if (permission === undefined) {
    // Navigation Items do not always have a permission associated
    // for example, admin menu items
    permission = []
  }

  const permissions = user.roles.reduce(
      (permissions, role) => permissions.concat(role.permissions),
      []
    ),
    foundPermissions = intersection(uniq(permissions), permission)

  // by default "isEqual" compares arrays with regard to order they appear with (deep comparison)
  // thus we need to sort arrays before comparing
  const hasPermission = anyMode
    ? foundPermissions.length > 0
    : isEqual(foundPermissions.sort(), permission.sort())

  return hasPermission
}

export const UserIsAdmin = (user: User) => {
  if (!user.roles) {
    return false
  }

  const adminRole = user.roles.filter(role => role.name === 'Administrator')

  return adminRole.length > 0
}
