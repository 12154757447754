/* @flow */

import * as React from 'react'
import ReactDomServer from 'react-dom/server'

import styled from 'styled-components'

import sortBy from 'lodash/sortBy'
import GlsLogo from '../../assets/images/carriers/gls.png'
import DhlLogo from '../../assets/images/carriers/dhl.png'

import { isEuCountry, isEuropeanCountry } from '../entities/utilities'
import { convertWeight } from '../../infrastructure/utilities'

export const carrierLogos = {
  dhl: DhlLogo,
  gls: GlsLogo,
}

export const shippingCodeTypes = [
  { label: 'Manual', value: 'manual' },
  { label: 'Auto fulfil', value: 'auto_fulfil' },
  { label: 'Combine with other shipments (no label)', value: 'combine' },
  { label: 'Coolrunner', value: 'coolrunner', app: 'Coolrunner' },
  { label: 'Prime Cargo', value: 'primecargo', app: 'PrimeCargo' },
  { label: 'DSV', value: 'dsv', app: 'Dsv' },
  { label: 'Rhenus', value: 'rhenus', app: 'Rhenus' },
  {
    label: '3PL',
    value: '3pl',
    app: 'Generic3pl',
  },
  {
    label: 'Picqer',
    value: 'picqer',
    app: 'Picqer',
  },
  {
    label: 'Ongoing',
    value: 'ongoing',
    app: 'Ongoing',
  },
  {
    label: 'Systemtransport',
    value: 'systemtransport',
    app: 'Systemtransport',
  },
  {
    label: 'Sendcloud',
    value: 'sendcloud',
    app: 'Sendcloud',
  },
  {
    label: 'Shipmondo',
    value: 'shipmondo',
    app: 'Shipmondo',
  },
  { label: 'Thetis Pack', value: 'thetis_pack', app: 'ThetisPack' },
  { label: 'TMC', value: 'tmc', app: 'Tmc' },
  { label: 'Webshipper', value: 'webshipper', app: 'Webshipper' },
]

export const carriers = [
  { label: 'Alpi', value: 'alpi' },
  { label: 'bla', value: 'bla' },
  { label: 'B2C Europe', value: 'b2c_europe' },
  { label: 'BURD', value: 'burd' },
  { label: 'Bring', value: 'bring' },
  { label: 'Budbee', value: 'budbee' },
  { label: 'Dachser', value: 'dachser' },
  { label: 'Danske Fragtmænd', value: 'danske_fragtmaend' },
  { label: 'DAO', value: 'dao' },
  { label: 'DB Schenker', value: 'db_schenker' },
  { label: 'Deutsche Post', value: 'deutsche_post' },
  { label: 'DHL', value: 'dhl' },
  { label: 'DPD', value: 'dpd' },
  { label: 'DSV', value: 'dsv' },
  { label: 'RHENUS', value: 'rhenus' },
  { label: 'FedEx', value: 'fedex' },
  { label: 'Gebrüder Weiss', value: 'gebruder_weiss' },
  { label: 'Geiss', value: 'geiss' },
  { label: 'Geodis', value: 'geodis' },
  { label: 'Gladeco', value: 'gladeco' },
  { label: 'GLS', value: 'gls' },
  { label: 'Leman', value: 'leman' },
  { label: 'Interfjord', value: 'interfjord' },
  { label: 'Hellmann', value: 'hellmann' },
  { label: 'Hermes', value: 'hermes' },
  { label: 'Müller & Partner', value: 'muller_partner' },
  { label: 'Parcel4You', value: 'parcel_4_you' },
  { label: 'Post nord', value: 'post_nord' },
  { label: 'PostNL', value: 'post_nl' },
  { label: 'Raaben', value: 'raaben' },
  { label: 'Royal Mail', value: 'royal_mail' },
  { label: 'SEKO', value: 'seko' },
  { label: 'TS Logistic', value: 'ts_logistic' },
  { label: 'UPS', value: 'ups' },
  { label: 'YOYO', value: 'yoyo' },
]

export const carrierCodeToLabel = code => {
  const carrier = carriers.find(c => c.value == code)

  return carrier ? carrier.label : code
}

export const carrierLogoOptions = [
  { label: 'GLS', value: 'gls', logo: GlsLogo },
  { label: 'DHL', value: 'dhl', logo: DhlLogo },
]

export const modesOfTransportation = [
  { label: 'Sea freight', value: 'sea' },
  { label: 'Air freight', value: 'air' },
  { label: 'Train', value: 'train' },
  { label: 'Road', value: 'road' },
  { label: 'Ex works', value: 'ex_works' },
]

export const REGION_EU = 'eu'

export const countryCodeToRegion = (
  country: string,
  domesticCountry = 'DK'
) => {
  if (country === domesticCountry) {
    return 'domestic'
  } else if (isEuCountry(country)) {
    return REGION_EU
  } else if (!isEuCountry(country) && isEuropeanCountry(country)) {
    return 'europe'
  } else {
    return 'abroad'
  }
}

export const regionOptions = [
  { label: 'Domestic', value: 'domestic' },
  { label: 'EU', value: 'eu' },
  { label: 'Europe (non-EU)', value: 'europe' },
  { label: 'Abroad', value: 'abroad' },
]

export const continentOptions = [
  { label: 'Asia', value: 'asia' },
  { label: 'Australia', value: 'australia' },
  { label: 'Europe', value: 'europe' },
]

export const createColliTypeLabel = colliType => {
  const dimensions = []
  if (colliType.width && colliType.width_type) {
    dimensions.push(`${parseInt(colliType.width)}${colliType.width_type}`)
  }
  if (colliType.height && colliType.height_type) {
    dimensions.push(`${parseInt(colliType.height)}${colliType.height_type}`)
  }
  if (colliType.length && colliType.length_type) {
    dimensions.push(`${parseInt(colliType.length)}${colliType.length_type}`)
  }

  return `${colliType.name} ${dimensions.join('/')}`
}

export const shipmentIsPicked = status => {
  return ['completed', 'wait_for_pickup', 'on_hold'].includes(status)
}

export const sortColliByLabel = colliList => {
  // If we simply sort by label, the order for delivery notes with more than 10 collis
  // would be: Colli 1, Colli 10, Colli 11, ..., Colli 2, Colli 3, ... That's why we
  // need to get the colli number as integer from the label and sort by it.
  return sortBy(colliList, colli => {
    const match = colli.label.match(/^Colli (\d+)$/)

    return match ? parseInt(match[1], 10) : colli.label
  })
}

// we created this as a shared function so that if you want to show to data
// on an invoice created from delivery note you can
export const createShipmentDeliveryNoteColliDocumentFooterData =
  deliveryNote => {
    let colliCount = 0
    const colliTypesList = []
    const colliTrackTraceNumberList = []
    let colliSpecifications = null
    let netWeightG = 0
    let grossWeightG = 0

    if (deliveryNote.colli) {
      for (let colli of deliveryNote.colli) {
        colliCount++

        if (colli.lines) {
          for (let line of colli.lines) {
            const product = line.product
            const unitWeightGrams = convertWeight(
              product.weight,
              product.weight_type,
              'g',
              false
            )

            netWeightG += unitWeightGrams * line.packed
          }
        }

        let colliWeightKg
        if (colli.weight && colli.weight_type) {
          colliWeightKg = convertWeight(
            colli.weight,
            colli.weight_type,
            'kg',
            1
          )

          grossWeightG += convertWeight(
            colli.weight,
            colli.weight_type,
            'g',
            true
          )
        }

        const colliType = colli.colli_type

        if (colliType) {
          let label = `1 ${createColliTypeLabel(colliType)}`
          if (colliWeightKg) {
            label += `/${colliWeightKg}kg`
          }

          colliTypesList.push(label)
        }

        colliTrackTraceNumberList.push(
          `${colli.label} no.: ${colli.track_trace}`
        )
      }

      colliSpecifications = generateTableOfColliSpecifications(
        deliveryNote.colli
      )
    }

    const colliTypesBody = colliTypesList.join('\n')

    const carrierCode =
      deliveryNote && deliveryNote.colli && deliveryNote.colli[0]
        ? deliveryNote.colli[0].carrier_code
        : null
    const carrierLabel = carrierCode ? carrierCodeToLabel(carrierCode) : ''

    const netWeightKg = convertWeight(netWeightG, 'g', 'kg', 1)
    const grossWeightKg = convertWeight(grossWeightG, 'g', 'kg', 1)

    return {
      carrier_code: carrierCode,
      carrier_label: carrierLabel,
      colli_specifications: ReactDomServer.renderToString(colliSpecifications),
      colli_count: colliCount,
      colli_track_trace_number_list: colliTrackTraceNumberList.join('\n'),
      colli_types_list: colliTypesBody,
      net_weight_g: netWeightG.toFixed(0),
      net_weight_kg: netWeightKg.toFixed(1),
      gross_weight_g: grossWeightG.toFixed(0),
      gross_weight_kg: grossWeightKg.toFixed(1),
    }
  }

const generateTableOfColliSpecifications = collis => {
  return (
    <ColliSpecificationsTable>
      <thead>
        <tr>
          <th>Colli</th>
          <th>Qty</th>
          <th>Weight</th>
          <th>Width</th>
          <th>Length</th>
          <th>Height</th>
          <th>Volume</th>
          <th>T&T</th>
        </tr>
      </thead>
      {collis.map(colli => {
        return (
          <tr>
            <td>{colli.ext_ref || colli.label}</td>
            <td>
              {colli.lines &&
                colli.lines.reduce((carry, line) => (carry += line.packed), 0)}
            </td>
            <td>
              {colli.weight}
              {colli.weight_type}
            </td>
            <td>
              {colli.width}
              {colli.width_type}
            </td>
            <td>
              {colli.length}
              {colli.length_type}
            </td>
            <td>
              {colli.height}
              {colli.height_type}
            </td>
            <td>{colli.width * colli.length * colli.height}</td>
            <td>{colli.track_trace}</td>
          </tr>
        )
      })}
    </ColliSpecificationsTable>
  )
}

const ColliSpecificationsTable = styled.table.attrs({
  className: 'table table-bordered table-condensed',
})``
