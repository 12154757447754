/* @flow */

import api from '../api'

import type {
  ApiResponse,
  Id,
  DocumentFooter,
  NavigationLink,
  ProductLocale,
  WebshopPage,
} from '../types'
import type { ProductTableSettings } from '../products/components/ProductTable/types'
import { createApiHook } from '../hooks'

// Country settings

export const getCountrySettings = options =>
  api.get('/customers/country-settings', {
    query: options,
  })

export const getCountrySettingsAdminList = options =>
  api.get('/customers/country-settings/admin-list', {
    query: options,
  })

export const createCountrySettings = settings =>
  api.post('/customers/country-settings/countries', {
    body: {
      settings,
    },
  })

export const updateCountrySettings = (id, settings) =>
  api.put(`/customers/country-settings/countries/${id}`, {
    body: {
      settings,
    },
  })

export const deleteCountrySettings = id =>
  api.delete(`/customers/country-settings/countries/${id}`)

export const createCountrySettingsRegion = settings =>
  api.post('/customers/country-settings/regions', {
    body: {
      settings,
    },
  })

export const updateCountrySettingsRegion = (id, settings) =>
  api.put(`/customers/country-settings/regions/${id}`, {
    body: {
      settings,
    },
  })

export const deleteCountrySettingsRegion = (id, deleteCountries) =>
  api.delete(`/customers/country-settings/regions/${id}`, {
    body: {
      delete_countries: deleteCountries,
    },
  })

// Document footers

export const createDocumentFooter = (
  footer: DocumentFooter
): Promise<ApiResponse<DocumentFooter>> =>
  api.post('/document-settings/footers', {
    body: {
      footer: footer,
    },
  })

export const deleteDocumentFooter = (id: Id) =>
  api.delete(`/document-settings/footers/${id}`)

export const getDocumentFooters = (): Promise<
  ApiResponse<{ footers: Array<DocumentFooter> }>
> => api.get('/document-settings/footers')

export const updateDocumentFooter = (
  id: Id,
  footer: DocumentFooter
): Promise<ApiResponse<DocumentFooter>> =>
  api.put(`/document-settings/footers/${id}`, {
    body: {
      footer: footer,
    },
  })

// Product table

export const updateProductTableSettings = (
  tableSettings: Array<{ table: string, settings: ProductTableSettings }>
) =>
  api.put('/product-table', {
    body: {
      data: tableSettings,
    },
  })

// Tablet

export const getTabletSettings = () => api.get('/tablet')

export const updateTabletSettings = settings =>
  api.put('/tablet', {
    body: {
      settings: settings,
    },
  })

// Exchange rates

export const getExchangeRates = (entityId: number) =>
  api.get(`/settings/${entityId}/exchange-rates`)

export const updateExchangeRates = (
  entityId: number,
  exchangeRates: Array<{ currency: string, exchange_rate: number }>
) =>
  api.put(`/settings/${entityId}/exchange-rates`, {
    body: {
      exchange_rates: exchangeRates,
    },
  })

export const updateExistingDataExchangeRates = (
  entityId: number,
  options: Object
) =>
  api.post(`/settings/${entityId}/update-existing-data-exchange-rates`, {
    body: {
      options,
    },
  })

// Attributes

export const getPersistedAttributes = loadTranslations =>
  api.get('/persisted-attributes', {
    query: {
      load_translations: loadTranslations ? 1 : 0,
    },
  })

type UpdateAttributeDetailsChanges = {
  [attributeId: string]: {
    use_as_filter_field: boolean,
  },
}

export const updateAttributeDetails = (
  changes: UpdateAttributeDetailsChanges
) =>
  api.post('/attributes/update', {
    body: {
      changes,
    },
  })

type UpdateAttributeValueDetailsChanges = {
  [attributeName: string]: {
    [valueName: string]: {
      code: string,
      description: string,
    },
  },
}

export const updateAttributeValueDetails = (
  changes: UpdateAttributeValueDetailsChanges,
  translations: any = {}
) =>
  api.post('/attributes/update-value-details', {
    body: {
      changes,
      translations,
    },
  })

// Product locales

export const getProductLocales = (): Promise<
  ApiResponse<{ product_locales: Array<ProductLocale> }>
> => api.get('/products/locales')

export const createProductLocale = (
  productLocale: $Shape<ProductLocale>
): Promise<ApiResponse<ProductLocale>> =>
  api.post('/products/locales', {
    body: {
      product_locale: productLocale,
    },
  })

export const updateProductLocale = (
  id: Id,
  productLocale: $Shape<ProductLocale>
): Promise<ApiResponse<ProductLocale>> =>
  api.put(`/products/locales/${id}`, {
    body: {
      product_locale: productLocale,
    },
  })

export const deleteProductLocale = (id: Id): Promise<ApiResponse<null>> =>
  api.delete(`/products/locales/${id}`)

// Accounting

export const getAccountingSettings = () => api.get(`/accounting/settings`)

export const getAccountingSettingsV2 = axiosOptions =>
  api.get(`/accounting/settings-v2`, {
    query: axiosOptions,
  })

export const updateAccountingSettings = data =>
  api.put(`/accounting/settings`, {
    body: {
      settings: data,
    },
  })

export const updateAccountingSettingsV2 = (axiosOptions, data) =>
  api.put(`/accounting/settings-v2`, {
    ...axiosOptions,
    body: {
      settings: data,
    },
  })

export const testAccountingSettings = (testValues, updatedAccountingSettings) =>
  api.post(`/accounting/test`, {
    body: {
      test: testValues,
      accounting_settings: updatedAccountingSettings,
    },
  })

export const getDocumentSettings = () => api.get('/document-settings')

// webshop navigation links

export const getWebshopNavigationLinks = (
  options?: Object
): Promise<ApiResponse<Array<NavigationLink>>> => {
  return api.get(`/webshop/navigation`, {
    query: options,
  })
}

export const updateWebshopNavigationLinks = (
  navigationLinks: Array<$Shape<NavigationLink>>
): Promise<ApiResponse<null>> =>
  api.put(`/webshop/navigation`, {
    body: {
      data: navigationLinks,
    },
  })

export const clearWebshopNavigationLinkCustomSorting = (
  linkId
): Promise<ApiResponse<null>> =>
  api.post(`/webshop/navigation/${linkId}/clear-custom-sort`)

// webshop pages

export const getWebshopPages = (
  options?: Object
): Promise<ApiResponse<{ pages: Array<WebshopPage> }>> => {
  return api.get(`/webshop/pages`, {
    query: options,
  })
}

export const updateWebshopPages = (
  pages: Array<$Shape<WebshopPage>>
): Promise<ApiResponse<null>> =>
  api.put(`/webshop/pages`, {
    body: {
      data: pages,
    },
  })

// Number series

export const getNumberSeries = (options?: BrunoRequestOptions) =>
  api.get('/number-series', {
    query: options,
  })

export const createNumberSeries = data =>
  api.post('/number-series', {
    body: {
      number_series: data,
    },
  })

export const updateNumberSeries = (id, data) =>
  api.put(`/number-series/${id}`, {
    body: {
      number_series: data,
    },
  })

export const deleteNumberSeries = id =>
  api.delete(`/number-series/${id}`)


// Entity settings

export const getOwnEntitySettings = (axiosOptions = {}, options) =>
  api.get('/settings/own-entity-settings', {
    query: options,
    ...axiosOptions,
  })

export const updateEntitySettings = (entityId: number, settings: Object) =>
  api.put(`/entities/${entityId}/settings`, {
    body: {
      data: settings,
    },
  })

export const updateContactInformation = (entityId: number, data: Object) =>
  api.put(`/entities/${entityId}/contact-information`, {
    body: {
      entity: data,
    },
  })

export const getImageFilters = () => api.get('/settings/image-filters')

// Agencies

export const getAgencies = options =>
  api.get(`/agencies`, {
    query: options,
  })

export const createAgency = data =>
  api.post(`/agencies`, {
    body: {
      agency: data,
    },
  })

export const updateAgency = (id, data) =>
  api.put(`/agencies/${id}`, {
    body: {
      agency: data,
    },
  })

// Price groups

export const getPriceGroups = (options?: Object) =>
  api.get(`/products/price-groups`, {
    query: options,
  })

export const getPriceGroup = (priceGroupId, options?: Object) =>
  api.get(`/products/price-groups/${priceGroupId}`, {
    query: options,
  })

export const createPriceGroup = data =>
  api.post(`/products/price-groups`, {
    body: {
      price_group: data,
    },
  })

export const updatePriceGroup = (priceGroupId, data) =>
  api.put(`/products/price-groups/${priceGroupId}`, {
    body: {
      price_group: data,
    },
  })

export const deletePriceGroup = priceGroupId =>
  api.delete(`/products/price-groups/${priceGroupId}`)

export const runPriceGroup = priceGroupId =>
  api.post(`/products/price-groups/${priceGroupId}/run`)

export const exportPriceGroups = () => api.post('/products/price-groups/export')

export const updateSingleProductWithPriceGroupPrices = productId =>
  api.post(`/products/price-groups/update-product`, {
    body: {
      product_id: productId,
    },
  })

// Commission policies

export const getCommissionPolicies = (axiosOptions, options?: Object) =>
  api.get(`/reports/commission-policies`, {
    query: options,
    ...axiosOptions,
  })

export const getCommissionPolicy = (
  axiosOptions,
  commissionPolicyId,
  options?: Object
) =>
  api.get(`/reports/commission-policies/${commissionPolicyId}`, {
    query: options,
    ...axiosOptions,
  })

export const createCommissionPolicy = data =>
  api.post(`/reports/commission-policies`, {
    body: {
      policy: data,
    },
  })

export const updateCommissionPolicy = (commissionPolicyId, data) =>
  api.put(`/reports/commission-policies/${commissionPolicyId}`, {
    body: {
      policy: data,
    },
  })

export const deleteCommissionPolicy = commissionPolicyId =>
  api.delete(`/reports/commission-policies/${commissionPolicyId}`)

// Printer settings

export const getPrinterUsers = () => api.get('/printers/printer-users')

export const createPrinterUser = () => api.post('/printers/printer-users')

export const getPrinterSettings = (options?: Object) =>
  api.get('/printers/settings', {
    query: options,
  })

export const updatePrinterSettings = (settings?: Object) =>
  api.put('/printers/settings', {
    body: {
      settings,
    },
  })

export const getPrinters = (options?: Object) => api.get('/printers')

export const getComputers = (options?: Object) => api.get('/printers/computers')

// Replenishment

export const getReplenishmentSettings = options =>
  api.get('/inventory/replenishment/settings', {
    query: options,
  })

export const updateReplenishmentSettings = settings =>
  api.put('/inventory/replenishment/settings', {
    body: {
      settings,
    },
  })

// Inventory alert policies

export const getInventoryAlertPolicies = options =>
  api.get(`/inventory/alert-policies`, {
    query: options,
  })

export const getInventoryAlertPolicy = (inventoryAlertPolicyId, options) =>
  api.get(`/inventory/alert-policies/${inventoryAlertPolicyId}`, {
    query: options,
  })

export const createInventoryAlertPolicy = data =>
  api.post('/inventory/alert-policies', {
    body: {
      policy: data,
    },
  })

export const updateInventoryAlertPolicy = (inventoryAlertPolicyId, data) =>
  api.put(`/inventory/alert-policies/${inventoryAlertPolicyId}`, {
    body: {
      policy: data,
    },
  })

export const deleteInventoryAlertPolicy = inventoryAlertPolicyId =>
  api.delete(`/inventory/alert-policies/${inventoryAlertPolicyId}`)

// Accounting years

export const getAccountingYears = (axiosOptions = {}, options = {}) =>
  api.get(`/accounting/years`, {
    ...axiosOptions,
    query: options,
  })

export const createAccountingYear = (axiosOptions, accountingYear) =>
  api.post('/accounting/years', {
    ...axiosOptions,
    body: {
      accounting_year: accountingYear,
    },
  })

export const updateAccountingYear = (axiosOptions, id, accountingYear) =>
  api.put(`/accounting/years/${id}`, {
    ...axiosOptions,
    body: {
      accounting_year: accountingYear,
    },
  })

export const deleteAccountingYear = (axiosOptions, id) =>
  api.delete(`/accounting/years/${id}`, {
    ...axiosOptions,
  })

// Internal companies

export const getInternalCompanies = (axiosOptions = {}, options = {}) =>
  api.get(`/internal-companies`, {
    ...axiosOptions,
    query: options,
  })

export const createInternalCompany = (axiosOptions, internalCompany) =>
  api.post('/internal-companies', {
    ...axiosOptions,
    body: {
      internal_company: internalCompany,
    },
  })

export const updateInternalCompany = (axiosOptions, id, internalCompany) =>
  api.put(`/internal-companies/${id}`, {
    ...axiosOptions,
    body: {
      internal_company: internalCompany,
    },
  })

export const deleteInternalCompany = (axiosOptions, id) =>
  api.delete(`/internal-companies/${id}`, {
    ...axiosOptions,
  })

export const getInternalCompanyRoutingSettings = axiosOptions =>
  api.get(`/internal-companies/routing-settings`, {
    ...axiosOptions,
  })

export const updateInternalCompanyRoutingSettings = (
  axiosOptions,
  routingSettings
) =>
  api.put(`/internal-companies/routing-settings`, {
    ...axiosOptions,
    body: {
      routing_settings: routingSettings,
    },
  })

// External document exchanges

export const getExternalDocumentExchanges = (axiosOptions = {}, options = {}) =>
  api.get(`/external-document-exchanges`, {
    ...axiosOptions,
    query: options,
  })

export const createExternalDocumentExchange = (
  axiosOptions,
  externalDocumentExchange
) =>
  api.post('/external-document-exchanges', {
    ...axiosOptions,
    body: {
      external_document_exchange: externalDocumentExchange,
    },
  })

export const updateExternalDocumentExchange = (
  axiosOptions,
  id,
  externalDocumentExchange
) =>
  api.put(`/external-document-exchanges/${id}`, {
    ...axiosOptions,
    body: {
      external_document_exchange: externalDocumentExchange,
    },
  })

export const deleteExternalDocumentExchange = (axiosOptions, id) =>
  api.delete(`/external-document-exchanges/${id}`, {
    ...axiosOptions,
  })
