/* @flow */

import * as React from 'react'
import styled from 'styled-components'

import {
  HeaderBox,
  HeaderBoxBody,
  HeaderBoxIcon,
  HeaderBoxTitle,
  HeaderBoxContent,
  HeaderBoxAction,
} from '../shared'
import WebshopActionButton from '../WebshopActionButton'

import { Tooltip } from '../../../shared'

const WebshopSessionManager = ({
  availableBrand,
  entity,
  setShowManualEditCustomerModal,
  setShowRetailerSelector,
  setShowSessionListModal,
  updateWebshopSession,
  webshopSession,
}) => {
  const { customer, drop, drop_filter_code } = webshopSession

  const customerCanMakeDropShipping = React.useMemo(() => {
    return customer ? customer.dropshipping : false
  }, [customer])

  const onConvertToDropshippingOrder = React.useCallback(() => {
    return updateWebshopSession({
      dropshipping: true,
    })
  }, [updateWebshopSession])

  const onConvertToNormalOrder = React.useCallback(() => {
    return updateWebshopSession({
      dropshipping: false,
    })
  }, [updateWebshopSession])

  const availableConnections = availableBrand
    ? availableBrand.retailer_connections.filter(
        connection => connection.b2b_access
      )
    : []

  return (
    <WebhopSessionBox>
      <HeaderBoxContent>
        <HeaderBoxTitle>
          <HeaderBoxIcon>
            <span className="lnr lnr-store" />
          </HeaderBoxIcon>
          <span>Current Order</span>
        </HeaderBoxTitle>
        <HeaderBoxBody>
          {entity.entity_type === 'brand' && (
            <CustomerNameContainer>
              <CustomerName>
                Customer: {webshopSession.customer.name}
              </CustomerName>

              <EditCustomerButton
                onClick={() => setShowManualEditCustomerModal(true)}
              >
                <span className="glyphicon glyphicon-pencil" />
              </EditCustomerButton>
            </CustomerNameContainer>
          )}
          {entity.entity_type === 'shop' && availableConnections.length > 1 && (
            <div>
              <span>Shopping as: {webshopSession.customer.name}</span>

              <EditCustomerButton onClick={() => setShowRetailerSelector(true)}>
                <span className="glyphicon glyphicon-pencil" />
              </EditCustomerButton>
            </div>
          )}

          {customerCanMakeDropShipping && (
            <div>
              {!webshopSession.dropshipping && (
                <WebshopActionButton
                  onClick={onConvertToDropshippingOrder}
                  size="xsmall"
                >
                  Convert to dropshipping order
                </WebshopActionButton>
              )}

              {webshopSession.dropshipping && (
                <>
                  <span className="label label-xxs label-warning">
                    Dropshipping
                  </span>
                  <Tooltip
                    tip="Convert to normal order"
                    id="convert-to-normal-order"
                  >
                    <ConvertToNormalOrderButton
                      color="transparent"
                      onClick={onConvertToNormalOrder}
                      size="xsmall"
                    >
                      <span className="glyphicon glyphicon-share-alt" />
                    </ConvertToNormalOrderButton>
                  </Tooltip>
                </>
              )}
            </div>
          )}

          {drop && <div>Drop: {drop.name}</div>}
          {drop_filter_code && <div>Drop code: {drop_filter_code}</div>}
        </HeaderBoxBody>
        <HeaderBoxAction>
          <WebshopActionButton
            color="white"
            onClick={() => setShowSessionListModal(true)}
            size="small"
          >
            Manage open orders
          </WebshopActionButton>
        </HeaderBoxAction>
      </HeaderBoxContent>
    </WebhopSessionBox>
  )
}

export default WebshopSessionManager

const WebhopSessionBox = styled(HeaderBox)``

const CustomerNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`

const CustomerName = styled.div`
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (min-width: 992px) {
    max-width: 310px;
  }

  @media (min-width: 1200px) {
    max-width: 410px;
  }
`

const EditCustomerButton = styled.button.attrs({
  size: 'xsmall',
  type: 'button',
})`
  background: transparent;
  border: none;
  font-size: 10px;
  outline: 0;
`

const ConvertToNormalOrderButton = styled(WebshopActionButton)``
